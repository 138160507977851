<template>
  <div>
    <h1 class="text-center mt-16">
        accounts -  الحســـــــــابات
    </h1>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
